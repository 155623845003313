import { useTranslation } from "react-i18next";

export const HelloSection = () => {
  const { t } = useTranslation();

  return (
    <div className="lg:m-[200px] md:m-[100px] sm:m-[50px] m-[40px]">
      <div className="centered">
        <div>
          <div className="centered mb-3">
            <p className="font-bold me-2 text-3xl lg:text-6xl md:text-4xl sm:text-3xl">
              {t("HelloSection.title")}
            </p>
            <div className="lg:w-[100px] lg:h-[100px] md:w-[75px] md:h-[75px] w-[30px]">
              <img src="./img/hello.svg" alt="hello" />
            </div>
          </div>
          <div className="text-center">
            <p className="lg:text-xl md:text-base text-main-blue sm:text-xs text-[12px]">
              {t("HelloSection.content")}{" "}
            </p>
            <p className="lg:text-xl md:text-base text-main-blue sm:text-xs text-[12px]">
              {t("HelloSection.content2")}
              <span className="font-bold text-main-peach">
                {t("HelloSection.contentBold")}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="centered mt-12">
        <a href="#game-scene-id">
          <img
            className="sm:w-full w-[50px]"
            alt="arrowDown"
            src="./img/ArrowDown.svg"
          />
        </a>
      </div>
      <div className="centered mt-10">
        <a
          href={t("ExplanationSection.FreeTest.btnLink")}
          target="_blank"
          rel="noreferrer"
          className=" drop-shadow-xl rounded-full bg-main-peach text-xl text-white hover:text-white lg:px-8 lg:py-5 md:px-8 md:py-5 sm:px-8 sm:py-5 px-2 py-2 hover:bg-opacity-80"
        >
          {t("ExplanationSection.FreeTest.btn")}
        </a>
      </div>
    </div>
  );
};
