import React, { useState, useEffect } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { TbCurrencyTugrik, TbMoneybag } from "react-icons/tb";
import axios from "axios";
import { PuffLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const WorkPlace = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [isPartTimer, setIsPartTimer] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/job/${id}`)
      .then((data) => {
        setData(data.data[0]);

        if (data.data[0].employmentStatus === "partTime") {
          setIsPartTimer(true);
        }
        if (data.data[0].gameLanguage === 0) {
          i18n.changeLanguage("mn");
        } else if (data.data[0].gameLanguage === 1) {
          i18n.changeLanguage("rs");
        } else if (data.data[0].gameLanguage === 2) {
          i18n.changeLanguage("en");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  if (data === null) {
    return (
      <div className="w-full centered min-h-screen ">
        <PuffLoader size={100} color="#F48D7E" />
      </div>
    );
  }
  const formattedSalary = data?.salary
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const renderHTMLWithListMarkers = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    doc.querySelectorAll("ul").forEach((ul) => {
      ul.style.listStyleType = "disc";
    });

    doc.querySelectorAll("ol").forEach((ol) => {
      ol.style.listStyleType = "decimal";
    });

    return <div dangerouslySetInnerHTML={{ __html: doc.body.innerHTML }} />;
  };
  return (
    <div>
      <div className="container mx-auto px-5 md:px-10 lg:px-20 xl:px-40">
        <div className="flex items-center justify-center my-10 container mx-auto w-full ">
          <ul className="flex items-center gap-1 sm:gap-5 text-[11px] sm:text-base ">
            <li className="font-semibold">{data?.createdBy}</li>
            <li>
              <BiSolidRightArrow />
            </li>
            <li className="underline text-[#056D71]">{data?.name}</li>
            <li>
              <BiSolidRightArrow />
            </li>
          </ul>
        </div>
        <div className="mt-20  ">
          <div className="text-center">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold">
              {data?.name}
            </h1>
          </div>
          <div className="mt-6 md:mt-10 flex md:flex-row items-start  justify-between">
            <div className="text-xs sm:text-lg flex items-center mb-2 md:mb-0">
              <span className="text-center me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="17"
                  viewBox="0 0 9 12"
                  fill="none"
                >
                  <path
                    d="M7.4973 4.74086L8.06203 9.76868C8.13806 10.4455 7.75573 11.0559 7.25581 11.0559H1.8159C1.31595 11.0559 0.933639 10.4455 1.00966 9.76868L1.57439 4.74086C1.63562 4.19581 1.97802 3.79346 2.38064 3.79346H6.69108C7.09368 3.79346 7.43608 4.19581 7.4973 4.74086Z"
                    stroke="#6A6A6A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.35142 2.11729C5.35142 1.50023 4.98622 1 4.5357 1C4.08517 1 3.71997 1.50023 3.71997 2.11729"
                    stroke="#6A6A6A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              {isPartTimer ? t("workPlace.parttime") : t("workPlace.fulltime")}
            </div>
            <div className=" flex items-center">
              <TbMoneybag className="me-1" />
              <p className="text-xs flex item-center sm:text-lg">
                {formattedSalary}
              </p>
              <span className="text-center ">
                <TbCurrencyTugrik />
              </span>
            </div>
            <div className="text-xs sm:text-lg flex items-center">
              <span className="text-center me-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="17"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M5.95374 1C3.21786 1 1 3.28334 1 6.09998C1 8.91659 3.21786 11.2 5.95374 11.2C8.68958 11.2 10.9075 8.91659 10.9075 6.09998C10.9075 3.28334 8.68958 1 5.95374 1Z"
                    stroke="#545454"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.125 9.33607C2.125 9.33607 3.2292 7.88477 5.95374 7.88477C8.6783 7.88477 9.78253 9.33607 9.78253 9.33607"
                    stroke="#545454"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.95389 6.10002C6.77468 6.10002 7.44001 5.41505 7.44001 4.57003C7.44001 3.72504 6.77468 3.04004 5.95389 3.04004C5.13311 3.04004 4.46777 3.72504 4.46777 4.57003C4.46777 5.41505 5.13311 6.10002 5.95389 6.10002Z"
                    stroke="#545454"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              {data?.departmentName}
            </div>
          </div>
        </div>
        <div className="border-2 rounded-xl mt-5 px-5 gap-5 md:px-8 py-8 flex flex-row sm:gap-10">
          <div className="flex-1 ms-2">
            <div className="font-bold">{t("workPlace.introduction")}</div>
            <div className="ms-7 mt-2">
              {renderHTMLWithListMarkers(data?.introductionText)}
            </div>
          </div>
          <div className="flex-1">
            <div className="font-bold">{t("workPlace.requiremenet")}</div>
            <div className="ms-7 mt-2">
              {renderHTMLWithListMarkers(data?.requirementText)}
            </div>
          </div>
        </div>

        <div className="flex items-center my-10 justify-center">
          <Link
            to="form"
            className="px-6 bg-main-peach flex items-center gap-2 py-3.5 text-base rounded-xl font-medium text-white hover:opacity-75 focus:ring-4 focus:outline-none focus:ring-blue-300 "
          >
            {t("workPlace.button")} <BiSolidRightArrow />
          </Link>
        </div>
      </div>
    </div>
  );
};
