import { useTranslation } from "react-i18next";

export const ExplanationSection = () => {
  const { t } = useTranslation();

  return (
    <div className="">
      <p className="lg:text-6xl  md:text-5xl sm:text-4xl text-xl font-bold centered lg:mb-[120px] md:mb-[120px] sm:mb-[120px] mb-[50px]">
        {t("ExplanationSection.title")}
      </p>
      {/* shinjleh uhaan deer suurilsan section */}
      <div className="lg:justify-center  md:justift-center lg:centered md:centered lg:flex md:flex gap-[115px] border border-dashed rounded-[15px] border-gray-500 pt-[60px] pb-[110px] px-10">
        <div className="">
          <div className="flex justify-center">
            <img
              className="lg:w-[150px] lg:h-[180px] md:w-[130px] md:h-[150px] sm:w-[150px] sm:h-[160px] w-[70px] h-[80px] "
              src="./img/explanations/1.1.svg"
              alt="magnifying-glass"
            />
          </div>
          <div>
            <div className="flex justify-center">
              <p className="  m-0 lg:w-[380px] md:w-[300px] sm:w-[350px] font-bold lg:text-4xl md:text-3xl sm:text-4xl text-xl w-[180px] text-center flex-wrap ">
                {t("ExplanationSection.First.title")}
              </p>
            </div>
            <div className="flex justify-center">
              <p className=" lg:w-[444px] md:w-[330px] sm:w-[250px] w-[250px] text-center mt-[16px] lg:text-lg md:text-base sm:text-xs text-[11px] lg:mb-0 md:mb-0 sm:mb-8 mb-5 ">
                {t("ExplanationSection.First.content")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <img
            className="lg:w-[546px] md:w-[346px] sm:w-[350px] "
            src="./img/explanations/1.2.png"
            alt="game-screen"
          />
        </div>
      </div>
      {/* batalgaajulsan section */}
      <div className="lg:justify-center md:justift-center lg:centered md:centered lg:flex md:flex  gap-[115px] border border-dashed rounded-[15px] border-gray-500 pt-[131px] pb-[146px] px-10">
        <div className="flex justify-center lg:mb-0 md:mb-0 sm:mb-0 mb-5">
          <img
            className="lg:w-[433px] md:w-[350px] sm:w-[280px] w-[180px]"
            src="./img/explanations/2.1.svg"
            alt="statistics"
          />
        </div>
        <div>
          <div className="flex justify-center">
            <img
              className="lg:w-[263px]  md:w-[200px] sm:w-[150px] w-[130px] "
              src="./img/explanations/2.2.svg"
              alt="checked"
            />
          </div>
          <div>
            <div className="flex justify-center">
              <p className="text-center font-bold lg:text-4xl md:text-3xl sm:text-2xl text-2xl mb-2">
                {t("ExplanationSection.Second.title")}
              </p>
            </div>
            <div className="flex justify-center">
              <p className="lg:w-[320px] md:w-[285px] sm:w-[225px] w-[225px] text-[12px] text-center lg:text-lg md:text-base sm:text-xs">
                {t("ExplanationSection.Second.content")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ungursun bus ireeduid chigelsen section */}
      <div className="lg:justify-center md:justift-center  lg:centered md:centered lg:flex md:flex gap-[115px] border border-dashed rounded-[15px] border-gray-500 pt-[85px] pb-[95px] px-10">
        <div>
          <div className="flex justify-center">
            <img src="./img/explanations/3.1.svg" alt="left-arrow" />
          </div>
          <div>
            <p className="lg:text-4xl lg:w-[450px] md:text-3xl md:w-[400px] md sm:text-xl text-2xl mt-5 font-bold  text-center">
              {t("ExplanationSection.Third.title")}
            </p>
            <p className="lg:text-lg  md:text-base sm:text-xs text-center text-[15px] lg:mb-0 md:mb-0 sm:mb-8 mb-5   mt-8">
              <span>{t("ExplanationSection.Third.contentSpan")}</span>
              <br />
              {t("ExplanationSection.Third.content")}
              <br /> {t("ExplanationSection.Third.content2")} <br />
              {t("ExplanationSection.Third.content3")}
            </p>
          </div>
        </div>
        <div>
          <img
            className="lg:w-[594px] md:w-[500px] sm:[450px]"
            src="./img/explanations/3.2.svg"
            alt="example"
          />
        </div>
      </div>
      {/* status iig bar aar haruulsan section*/}
      <div className="lg:justify-center md:justift-center lg:centered md:centered lg:flex md:flex lg:gap-[115px] md:gap-[40px] sm:gap-[45px] border border-dashed rounded-[15px] border-gray-500 py-[100px] px-5 ">
        <div className="lg:mb-0 md:mb-0 sm:mb-8 mb-10">
          <div className="flex justify-center">
            <p className="lg:text-2xl lg:w-[482px] md:text-xl md:w-[360px] sm:text-xl sm:w-[350px] text-[16px] text-center font-bold  mb-[50px]">
              {t("ExplanationSection.Fourth.left.title")}
            </p>
          </div>
          <div className="lg:ps-0 md:ps-0 sm:ps-20 ps-5 ">
            <div className="flex items-center gap-2">
              <img
                className="lg:w-[30px] lg:h-[30px] md:w-[20px] md:-h[25px] sm:w-[20px] sm:h-[20px] w-[25px]"
                src="./img/explanations/checkedMinimal.png"
                alt="checked"
              />
              <p className="lg:w-[402px]  lg:text-lg md:text-base md:w-[302px] sm:text-xs sm:w-[302px] text-xs w-[180px] ps-3  py-[2px] border rounded-lg text-white font-bold border-[#6fd85b] bg-[#6fd85b] shadow-xl  ">
                {t("ExplanationSection.Fourth.left.FirstBar")}
              </p>
              <p className="lg:ms-4 md:ms-4 sm:ms-4 ms-1 font-bold">65%</p>
            </div>
            <div className="flex items-center my-5 gap-2">
              <img
                className="lg:w-[30px] lg:h-[30px] md:w-[20px] md:-h[25px] sm:w-[20px] sm:h-[20px] w-[25px]"
                src="./img/explanations/checkedMinimal.png"
                alt="checked"
              />
              <p className="lg:w-[345px] lg:text-lg md:text-base md:w-[245px] sm:text-xs sm:w-[245px] text-xs w-[140px] ps-3 py-[2px] border rounded-lg text-white font-bold border-[#6fd85b] bg-[#6fd85b] shadow-xl  ">
                {t("ExplanationSection.Fourth.left.SecondBar")}
              </p>
              <p className="lg:ms-4 md:ms-4 sm:ms-4 ms-1 font-bold">58%</p>
            </div>
            <div className="flex items-center gap-2">
              <img
                className="lg:w-[30px] lg:h-[30px] md:w-[20px] md:h-[25px] sm:w-[20px] sm:h-[20px] w-[25px]"
                src="./img/explanations/checkedMinimal.png"
                alt="checked"
              />
              <p className="lg:w-[284px] lg:text-lg md:text-base md:w-[184px] sm:text-xs sm:w-[184px] text-xs w-[120px] ps-3 py-[2px] border rounded-lg text-white font-bold border-[#6fd85b] bg-[#6fd85b] shadow-xl  ">
                {t("ExplanationSection.Fourth.left.ThirdBar")}
              </p>
              <p className="lg:ms-4 md:ms-4 sm:ms-4 ms-1 font-bold">45%</p>
            </div>
            <div className="flex items-center gap-2 mt-5">
              <img
                className="lg:w-[30px] lg:h-[30px] md:w-[20px] md:-h[25px] sm:w-[20px] sm:h-[20px] w-[25px]"
                src="./img/explanations/warning.svg"
                alt="checked"
              />
              <p className="lg:text-lg md:text-base sm:text-xs text-xs  lg:px-1 md:px-1 sm:px-1 px-[1px]  py-[2px] border rounded-lg font-bold border-[#FFD764] bg-[#FFD764] shadow-xl  ">
                {t("ExplanationSection.Fourth.left.FourthBar")}
              </p>
              <p className="lg:ms-4 md:ms-4 sm:ms-4 ms-1 font-bold">13%</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-center">
            <img
              className="h:full lg:w-[351px] md:w-[250px] sm:w-[199px] w-[250px]"
              src="./img/explanations/4.2.svg"
              alt="versus"
            />
          </div>
          <div>
            <div className="flex justify-center">
              <p className="lg:text-4xl lg:w-[430px] md:text-2xl md:w-[300px] sm:text-2xl sm:w-[300px]  text-xl w-[250px] font-bold  text-center ">
                {t("ExplanationSection.Fourth.right.title")}
              </p>
            </div>
            <div className="flex justify-center">
              <p className="lg:w-[370px] lg:text-lg md:w-[300px] md:text-[14px] sm:text-base sm:w-[350px] text-xs w-[260px] text-center mt-5">
                {t("ExplanationSection.Fourth.right.content")}
                <span className="font-bold">
                  {" "}
                  {t("ExplanationSection.Fourth.right.contentBold")}
                </span>
                {t("ExplanationSection.Fourth.right.content2")}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/*Une tulburgui turshij uzeh section*/}
      <div className=" lg:mt-[150px] md:mt-[150px] sm:mt-[150px] mt-[80px]">
        <div className="centered">
          <p className="lg:text-3xl md:text-2xl sm:text-xl  lg:w-[860px] md:w-[680px] sm:w-[580px] lg:leading-[60px] md:leading-[50px] sm:leading-[30px] text-[16px] lg:px-0 md:px-0 sm:px-0 px-5 text-center">
            {t("ExplanationSection.FreeTest.title")}
            {t("ExplanationSection.FreeTest.titleSpan")}
          </p>
        </div>
        <div className="centered lg:my-[120px] md:my-[120px] sm:my-[120px] my-[60px]">
          <a
            href={t("ExplanationSection.FreeTest.btnLink")}
            target="_blank"
            rel="noreferrer"
            className=" drop-shadow-xl rounded-full bg-[#f6b693] text-xl text-white hover:text-white lg:px-8 lg:py-5 md:px-8 md:py-5 sm:px-8 sm:py-5 px-2 py-2 hover:bg-opacity-80"
          >
            {t("ExplanationSection.FreeTest.btn")}
          </a>
        </div>
      </div>
    </div>
  );
};
