import { useTranslation } from "react-i18next";

export const PartnerOrganizations = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className=" border border-dashed border-gray-500 pt-[81px] px-10">
        <p className="font-bold centered lg:text-5xl  lg:mb-[130px] md:text-3xl md:mb-[80px] sm:text-xl sm:mb-[50px] text-xl mb-[50px] ">
          {t("PartnerOrganizationSection.title")}
        </p>
        <div className="centered lg:flex-row md:flex-row sm:flex-col flex-col lg:gap-[120px] md:gap-[80px] sm:gap-[40px] pb-[59px]">
          <div className="lg:mb-0 md:mb-0 sm:mb-0 mb-10">
            <div className="flex justify-center">
              <img src="./img/logos/mstars.svg" alt="Mstars Logo" />
            </div>
            <div>
              <p className="font-bold text-center lg:mt-[150px] md:pt-[30px] md:mt-[60px] sm:mt-[30px] mt-[30px] ">
                {t("PartnerOrganizationSection.MStars")}
              </p>
            </div>
          </div>
          <div className="lg:mb-0 md:mb-0 sm:mb-0 mb-10">
            <div className="flex justify-center">
              <img src="./img/logos/microsoft.svg" alt="Mstars Logo" />
            </div>
            <div>
              <p className="font-bold text-center lg:mt-[100px]  md:mt-[60px] sm:mt-[30px] mt-[20px] ">
                {t("PartnerOrganizationSection.Microsoft")}
              </p>
            </div>
          </div>
          <div className="lg:mb-0 md:mb-0 sm:mb-0 mb-20">
            <div className="flex justify-center">
              <img src="./img/logos/silkway.svg" alt="Silkway Logo" />
            </div>
            <div className="flex justify-center">
              <p className="font-bold text-center w-full lg:mt-[100px]  md:mt-[60px] sm:mt-[30px] mt-[20px] ">
                {t("PartnerOrganizationSection.Silkway")}
              </p>
            </div>
          </div>
          <div className="lg:mb-0 md:mb-0 sm:mb-0 mb-10">
            <div className="flex justify-center">
              <img src="./img/logos/ucla.svg" alt="Mstars Logo" />
            </div>
            <div className="flex justify-center">
              <p className="font-bold text-center  lg:mt-[140px] md:mt-[60px] sm:mt-[30px] w-[251px] mt-[30px] ">
                {t("PartnerOrganizationSection.UCLA")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
