import { NavbarComponent } from "./NavbarComponent";
import { HelloSection } from "./HelloSection";
import GameScene from "./GameScene";
import { SponserSection } from "./SponserSection";
import { ExplanationSection } from "./ExplanationSection";
import { PartnerOrganizations } from "./PartnerOrganizations";
import { FaqSection } from "./FaqSection";
import { MeetingSection } from "./MeetingSection";
import { Footer } from "./Footer";
import { LanguageProvider } from "./context/LanguageContext";

export const AllPages = () => {
  return (
    <div>
      <NavbarComponent />
      <HelloSection />
      <LanguageProvider>
        <GameScene />
      </LanguageProvider>
      <SponserSection />
      <ExplanationSection />
      <PartnerOrganizations />
      <FaqSection />
      <MeetingSection />
      <Footer />
    </div>
  );
};
