import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";

export const NavbarComponent = () => {
  const { t } = useTranslation();
  // const menuItems = [
  //   {
  //     title: t("Navbar.li1"), // Use the t function outside the object definition
  //     link: "/#",
  //     id: 1,
  //   },
  //   {
  //     title: t("Navbar.li2"), // Example for other translations
  //     link: "/#",
  //     id: 2,
  //   },
  //   {
  //     title: t("Navbar.li3"),
  //     link: "/#",
  //     id: 3,
  //   },
  // ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="mt-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center w-[100%] justify-between ">
            <div className="flex-shrink-0">
              <a href="/#" className="text-white text-lg font-semibold">
                <img
                  className="lg:w-full md:w-full sm:w-[50%] w-[50%]"
                  src="./img/logos/MetacogLogo.svg"
                  alt=""
                />
              </a>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex justify-end items-center space-x-4">
                <div className="centered ">
                  <LanguageSelector />
                </div>

                <a
                  href="https://calendly.com/metacog-metrics/metacog-demo"
                  target="_blank"
                  rel="noreferrer"
                  className="text-white bg-main-peach hover:bg-opacity-75 focus:outline-none  focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center "
                >
                  {t("MeetingSection.btn")}
                </a>
                <a
                  className="hover:text-white hover:bg-black font-medium rounded-full text-sm px-5 py-2.5 text-center"
                  target="_blank"
                  rel="noreferrer"
                  href="https://app.metacog.mn"
                >
                  {t("Navbar.lgnBtn")}
                </a>
              </div>
            </div>
          </div>
          <div className="-mr-2 flex md:hidden">
            <LanguageSelector />

            <button
              type="button"
              className="bg-main-peach inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-main-peach focus:ring-white"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
              onClick={toggleMenu}
            >
              <span className="sr-only">Open main menu</span>
              <div
                className={`block h-6 w-6 flex justify-center items-center ${
                  isOpen ? "block" : "hidden"
                }`}
              >
                <AiOutlineClose className="h-10 w-10" />
              </div>
              <svg
                className={`block h-6 w-6 ${isOpen ? "hidden" : "block"}`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke={"#fff"}
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
              <svg
                className={`hidden h-6 w-6 ${isOpen ? "block" : "hidden"} `}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div
        className={`md:hidden shadow-xl ${isOpen ? "block" : "hidden"}`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 flex flex-col">
          <a
            href="https://calendly.com/metacog-metrics/metacog-demo"
            target="_blank"
            className="text-white bg-main-peach w- hover:bg-opacity-75 focus:outline-none  focus:ring-red-300 font-medium rounded-[8px]  text-sm px-5 py-2.5 text-center "
            rel="noreferrer"
          >
            {t("MeetingSection.btn")}
          </a>
          <a
            className="hover:text-white hover:bg-black w- font-medium  text-sm px-5 py-2.5 rounded-[8px] text-center"
            target="_blank"
            rel="noreferrer"
            href="https://app.metacog.mn"
          >
            {t("Navbar.lgnBtn")}
          </a>
        </div>
      </div>
    </nav>
  );
};
