import React, { useState } from "react";

const Accordion = ({ id, title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border  border-gray-300 rounded-md lg:mb-10 md:mb-5 sm:mb-5 mb-10  border-none w-100% lg:px-0 md:px-0 sm:px-0 px-5">
      <div className="centered lg:mx-[250px] md:mx-[180px] sm:mx-[130px]">
        <div>
          <button
            className="w-full flex items-center justify-center lg:p-4 md:p-4 sm:p-4 p-2 focus:outline-none"
            onClick={toggleAccordion}
            aria-expanded={isOpen ? "true" : "false"}
            aria-controls={`accordion-content-${id}`}
          >
            <p className=" lg:text-[25px] md:text-[20px] sm:text-[15px] text-[15px] font-bold">
              {title}
            </p>
            <svg
              className={`lg:w-10 lg:h-10 md:w-5 md:h-5 sm:w-4 sm:h-4 lg:ms-5 md:ms-5 sm:ms-3 ms-1  w-5 h-5  transition-transform ${
                isOpen ? "transform rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {isOpen && (
            <div
              id={`accordion-content-${id}`}
              className="lg:p-4 md:p-3 sm-p-1 "
              role="region"
              aria-labelledby={`accordion-button-${id}`}
            >
              <p className="lg:px-20 md:px-10 sm:px-5 border-t-3 border-[#000] lg:pt-10 md:pt-5 sm:pt-3 lg:text-base md:text-xs sm:text-xs text-[11px] pt-3">
                {content}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
