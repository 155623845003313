import Accordion from "./Accordion";
import { useTranslation } from "react-i18next";

export const FaqSection = () => {
  const { t } = useTranslation();

  return (
    <div>
      <p className="text-6xl font-bold mt-[105px] ms-[130px]">
        {t("FaqSection.title")}
      </p>
      <div className="centered mt-[36px]">
        <div className="">
          <Accordion
            title={t("FaqSection.Accordion.First.title")}
            content={t("FaqSection.Accordion.First.content")}
          />
          <Accordion
            title={t("FaqSection.Accordion.Second.title")}
            content={t("FaqSection.Accordion.Second.content")}
          />
          <Accordion
            title={t("FaqSection.Accordion.Third.title")}
            content={t("FaqSection.Accordion.Third.content")}
          />
          <Accordion
            title={t("FaqSection.Accordion.Fourth.title")}
            content={t("FaqSection.Accordion.Fourth.content")}
          />
        </div>
      </div>
    </div>
  );
};
