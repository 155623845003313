import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import "./styles/App.css";
import { I18nextProvider } from "react-i18next";
import i18n from "./components/i18n";
import PuffLoader from "react-spinners/PuffLoader";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <Suspense
            fallback={
              <div className="w-full centered min-h-screen ">
                <PuffLoader size={100} color="#F48D7E" />
              </div>
            }
          >
            <App />
          </Suspense>
        </HelmetProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
