import { Route, Routes } from "react-router-dom";
import { AllPages } from "./components/AllPages";
import { WorkPlace } from "./components/cvForm/WorkPlace";
import { Submitted } from "./components/cvForm/Submitted";
import { FormSection } from "./components/cvForm/FormSection";
import { Helmet } from "react-helmet";

const Page1 = () => {
  return (
    <div>
      <Helmet>
        <title>Metacog</title>
        <meta property="og:url" content="https://www.metacog.mn" />
        <meta
          property="og:image"
          content="https://www.metacog.mn/thumbnail.jpeg"
        />
        <meta property="og:image:alt" content="metacog" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="We are hiring people" />
        <meta property="og:title" content="Metacog" />
      </Helmet>
      <div>
        <AllPages />
      </div>
    </div>
  );
};

const Page2 = () => {
  return (
    <div>
      <Helmet>
        <title>Metacog Job</title>
        <meta property="og:url" content="https://www.metacog.mn/job" />
        <meta property="og:image" content="https://www.metacog.mn/thumb.png" />
        <meta property="og:image:alt" content="metacog job" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Check out our job openings" />
        <meta property="og:title" content="Metacog Jobs" />
      </Helmet>
      <div>
        <WorkPlace />
      </div>
    </div>
  );
};

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Page1 />} />
        <Route path="/job/:id" element={<Page2 />} />
        <Route path="/job/:id/form" element={<FormSection />} />
        <Route path="/job/:id/submitted" element={<Submitted />} />
      </Routes>
    </div>
  );
}

export default App;
