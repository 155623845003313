import React, { useEffect, useRef, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { EditorState } from "draft-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { PuffLoader } from "react-spinners";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const FormSection = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [data, setData] = useState(null);
  const isMountedRef = useRef(true);
  const [selectedFileName, setSelectedFileName] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/job/${id}`)
      .then((data) => {
        setData(data.data[0]);
        if (data.data[0].gameLanguage === 0) {
          i18n.changeLanguage("mn");
        } else if (data.data[0].gameLanguage === 1) {
          i18n.changeLanguage("rs");
        } else if (data.data[0].gameLanguage === 2) {
          i18n.changeLanguage("en");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  if (data === null) {
    return (
      <div className="w-full centered min-h-screen ">
        <PuffLoader size={100} color="#F48D7E" />
      </div>
    );
  }

  const onEditorStateChange = (newEditorState) => {
    if (isMountedRef.current) {
      setEditorState(newEditorState);
    }
  };

  // zurag avah func

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 25 * 1024 * 1024) {
        alert("File size must be 25MB or below.");
        return;
      }

      setSelectedFileName(file.name);
    } else {
      setSelectedFileName("");
    }
  };

  const assessmentName = data.job;
  //data avah
  const handleSubmit = async (event) => {
    event.preventDefault();

    const textContent = editorState;

    const formData = new FormData();
    formData.append("about", textContent);
    // Add other form fields to the FormData object
    formData.append("assessmentId", id);
    formData.append("assessmentName", assessmentName);
    formData.append("firstName", event.target.floating_first_name.value);
    formData.append("lastName", event.target.floating_last_name.value);
    formData.append("email", event.target.floating_email.value);
    formData.append("phoneNumber", event.target.floating_phone_number.value);
    formData.append("social", event.target.floating_LinkedIn.value);
    formData.append("file", event.target.file_upload.files[0]);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/assessment/apply/player`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        toast.success("Form submitted successfully");
        navigate(`/job/${id}/submitted`);
      } else {
        toast.error("Form submission failed ");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className="my-5 mx-5">
      <div className="flex items-center justify-center my-10 container mx-auto w-full ">
        <ul className="flex items-center gap-1 sm:gap-5 text-[11px] sm:text-base ">
          <li className="font-semibold">{data?.createdBy}</li>
          <li>
            <BiSolidRightArrow />
          </li>
          <li className="underline font-semibold">{data?.job}</li>
          <li>
            <BiSolidRightArrow />
          </li>
          <li className="underline text-[#056D71]">{t("Form.breadCrumbs")}</li>
        </ul>
      </div>
      <div className="container px-[20px] md:px-[140px] py-10  border-2 rounded-lg my-10 mx-auto">
        <form onSubmit={handleSubmit} className="space-y-6  ">
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="floating_last_name"
                id="floating_last_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_last_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {t("Form.lastname")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="text"
                name="floating_first_name"
                id="floating_first_name"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_first_name"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {t("Form.firstname")}
              </label>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-6">
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="email"
                name="floating_email"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_email"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {t("Form.email")}
              </label>
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <input
                type="tel"
                name="floating_phone_number"
                id="floating_phone_number"
                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
              <label
                htmlFor="floating_phone_number"
                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                {t("Form.phone")}
              </label>
            </div>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            {/* <input
              type=""
              name="floating_desc"
              id="floating_desc"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_desc"
              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Таны талаар бид юуг мэдэх ёстой вэ?
            </label> */}
            <div className="border rounded p-3 h-[150px] flex justify-center item-center ">
              <ReactQuill
                value={editorState}
                placeholder={t("Form.about")}
                onChange={onEditorStateChange}
                className="w-full  h-[60px]"
              />
            </div>
          </div>
          <div className="relative z-0 w-full mb-6 group margin-top">
            <input
              type="text"
              name="floating_LinkedIn"
              id="floating_LinkedIn"
              className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              defaultValue="https://www.linkedin.com/in/"
              required
            />
            <label
              htmlFor="floating_LinkedIn"
              className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-12 md:-translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-12 md:peer-focus:-translate-y-8"
            >
              {t("Form.linkedin")}
            </label>
          </div>
          <div className="relative z-0 w-full mb-[30px] group">
            <input
              type="file"
              accept="/*"
              name="file_upload"
              id="file_upload"
              className="hidden"
              placeholder=""
              required
              onChange={handleFileChange}
            />
            <label
              htmlFor="file_upload"
              className={`peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] ${
                selectedFileName
                  ? "peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                  : ""
              } `}
            >
              {selectedFileName ? t("Form.CvChosed") : t("Form.CvInput")}
            </label>
            <button
              type="button"
              onClick={() => {
                document.getElementById("file_upload").click();
              }}
              className="mt-2 px-4 py-2 bg-[#fff] border-2 rounded-md hover:bg-[#f0f0f0]"
            >
              <AiOutlinePlus />
            </button>
            <div className="mt-4" id="image_preview">
              {selectedFileName && (
                <>
                  <p>{selectedFileName}</p>
                </>
              )}
            </div>
          </div>
          <button
            type="submit"
            className="text-white w-4/1 bg-main-peach hover:opacity-75 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            {t("Form.button")}
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};
