import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import { useTranslation } from "react-i18next";

export const SponserSection = () => {
  const { t } = useTranslation();

  return (
    <div className="border border-dashed rounded-lg border-gray-500 p-14 lg:mb-[120px] md:mb-[120px] sm:mb-[120px] mb-[50px]">
      <p className="centered font-bold text-center text-black md:text-xl md:mb-8 sm:text-[20px] sm:mb-2  text-[12px] mb-5">
        {t("SponserSection.title")}
      </p>
      <div className="centered my-[100px]">
        <Swiper
          className="flex items-center h-full"
          modules={[Autoplay, FreeMode]}
          grabCursor={true}
          autoplay={true}
          breakpoints={{
            0: {
              slidesPerView: 2.5,
              spaceBetween: 50,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
        >
          <SwiperSlide>
            <div className="flex justify-center lg:mb-0 md:m-0 sm:m-8  mb-5">
              <a
                className="flex items-center justify-center mt-2 md:mt-5"
                href="https://mcs.mn/mn/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./img/sponsers/mcs.svg" alt="Mcs" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="flex items-center justify-center">
            <div className="flex justify-center ">
              <a
                className="flex items-center justify-center mt-2 md:mt-6"
                href="http://www.teso.mn"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./img/sponsers/teso.svg" alt="Teso" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                className="flex items-center justify-center mt-2 md:mt-5"
                href="https://cumongol.mn/"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./img/sponsers/cu.svg" alt="Cu" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center mt-3">
              <a
                href="https://www.unitel.mn/unitel/"
                target="_blank"
                className="flex items-center justify-center mt-2 md:mt-5"
                rel="noreferrer"
              >
                <img src="./img/sponsers/unitel.png" alt="Unitel" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex mt-2 justify-center ">
              <a
                href="https://www.coca-colacompany.com/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center mt-2 md:mt-5"
              >
                <img src="./img/sponsers/cocacola.png" alt="CocaCola" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://e-shop.nomin.mn/"
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center mt-2 md:mt-5"
              >
                <img src="./img/sponsers/nomin.svg" alt="Noming" />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                className="flex items-center justify-center mt-2 md:mt-5"
                href="https://astanahub.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[170px] mt-2"
                  src="./img/sponsers/astana.png"
                  alt="astana.png"
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://icemark.mn"
                className="flex items-center justify-center "
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[150px] mt-2 md:mt-4"
                  src="./img/sponsers/icemark.png"
                  alt="icemark.png"
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://www.invescore.mn"
                className="flex items-center justify-center "
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[150px] mt-2 md:mt-4"
                  src="./img/sponsers/invescore.png"
                  alt="invescore.png"
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://www.mandalfinancialgroup.mn"
                className="flex items-center justify-center mt-2 md:mt-5"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./img/sponsers/mandal.png" alt="mandal.png" />
              </a>
            </div>
          </SwiperSlide>{" "}
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://m-bank.mn/"
                className="flex items-center justify-center"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[150px] mt-2 md:mt-4"
                  src="./img/sponsers/mBank.png"
                  alt="mbank.png"
                />
              </a>
            </div>
          </SwiperSlide>{" "}
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://pharmacy.monos.mn/"
                className="flex items-center justify-center"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[120px] mt-2 md:mt-4"
                  src="./img/sponsers/monos.png"
                  alt="monos.png"
                />
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex justify-center ">
              <a
                href="https://www.ncd.mn/"
                className="flex items-center justify-center mt-2 md:mt-5"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-[150px] mt-2"
                  src="./img/sponsers/ncd.png"
                  alt="ncd.png"
                />
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      <div className=" mt-10 text-center">
        <a
          href={t("SponserSection.trybtnLink")}
          target="_blank"
          rel="noreferrer"
          className=" drop-shadow-xl rounded-full bg-[#f6b693] text-white hover:text-white px-3 py-3 hover:bg-opacity-80"
        >
          {t("SponserSection.trybtn")}
        </a>
      </div>
    </div>
  );
};
