import { useTranslation } from "react-i18next";
import { BiSolidPhoneCall } from "react-icons/bi";
import { ImLocation } from "react-icons/im";
export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-[#f5f5f5]">
      <div className="mx-auto w-full max-w-screen-xl">
        <div className="grid grid-cols-1 gap-8 md:px-4 sm:px-[40px] px-10 py-6 lg:py-8 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 ">
          <div>
            <a href="/#">
              <img
                className="mt-[80px]"
                src="./img/logos/MetacogLogo.svg"
                alt=""
              />
            </a>
            <p className="mt-[60px]">© Metacog Metrics LLC</p>
          </div>

          <div>
            <h2 className="mb-6 text-base font-bold  text-main-peach   ">
              {t("Footer.Platform.title")}
            </h2>
            <ul className="text-gray-500  font-medium">
              <li className="mb-4">
                <a
                  href="https://app.metacog.mn"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  {t("Footer.Platform.li1")}
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://calendly.com/metacog-metrics/metacog-demo"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  {t("Footer.Platform.li2")}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-base font-bold  text-main-peach   ">
              {t("Footer.Company.title")}
            </h2>
            <ul className="text-gray-500  font-medium">
              <li className="mb-4">
                <a href="/#" className="hover:underline">
                  {t("Footer.Company.li1")}
                </a>
              </li>
              <li className="mb-4">
                <a href="/#" className="hover:underline">
                  {t("Footer.Company.li2")}
                </a>
              </li>
              <li className="mb-4">
                <a href="/#" className="hover:underline">
                  {t("Footer.Company.li3")}
                </a>
              </li>
              <li className="mb-4">
                <a href="/#" className="hover:underline">
                  {t("Footer.Company.li4")}{" "}
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-base font-bold  text-main-peach   ">
              {t("Footer.Communicate.title")}
            </h2>
            <ul className="text-gray-500">
              <li className="mb-4  flex font-semibold w-full ">
                <ImLocation className="text-[20px] me-2 mt-[2px]"  />
                {t("Footer.Communicate.Address")}
              </li>
              <li className="mb-4  flex items-center font-semibold w-full ">
                <BiSolidPhoneCall className="text-[20px] me-2" />
                +976 7777-4001
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
