import PuffLoader from "react-spinners/PuffLoader";
import React, { useContext, useState, useEffect, useRef } from "react";
import { LanguageContext } from "./context/LanguageContext";

const GameScene = () => {
  const language = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    function handleIframeLoad() {
      setTimeout(() => {
        setIsLoading(false);
      }, 5000);
    }

    if (iframeRef.current) {
      const iframeContent = iframeRef.current.contentWindow;
      if (iframeContent && iframeContent.document.readyState === "complete") {
        handleIframeLoad();
      } else {
        iframeRef.current.addEventListener("load", handleIframeLoad);
      }
    }

    return () => {
      iframeRef.current?.removeEventListener("load", handleIframeLoad);
    };
  }, []);

  return (
    <div id="game-scene-id" className="relavtive w-100">
      <div className={`loading ${isLoading ? "active absolute  mb-11" : "hidden"}`}>
        <PuffLoader size={140} color="#F48D7E" />
      </div>
      <div className={`iframe-container ${!isLoading ? "active mb-11" : "relative z-0 mb-11"} `} id="Game-Section">
        {language === "mn" ? (
          <iframe
            className={`w-full lg:h-[600px] md:h-[500px] sm:h-[300px] h-[350px] ${isLoading ? "" : "loading-finished"}`}
            title="gameMn"
            src="https://the-ganbayar.github.io/metacog/"
            ref={iframeRef}
          ></iframe>
        ) : (
          <iframe
            className={`w-full lg:h-[600px] md:h-[500px] sm:-h-[300px] h-[350px] ${isLoading ? "" : "loading-finished"}`}
            src="https://the-ganbayar.github.io/metacogEn/"
            title="gameEn"
            ref={iframeRef}
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default GameScene;
