import { useTranslation } from "react-i18next";

export const MeetingSection = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-36 ">
      <div className="centered">
        <p className="text-center  lg:text-3xl md:text-2xl sm-text:xl lg:w-[900px] md:w-[710px] sm:w-[510px] p-10 font-bold ">
          {t("MeetingSection.title")}
        </p>
      </div>
      <div className="centered">
        <a
          href="https://calendly.com/metacog-metrics/metacog-demo"
          target="_blank"
          rel="noreferrer"
          className="my-[50px] drop-shadow-xl rounded-full bg-[#f6b693] text-xl text-white hover:text-white lg:px-8 lg:py-5 md:px-8 md:py-5 sm:px-8 sm:py-5 px-2 py-1 hover:bg-opacity-80"
        >
          {t("MeetingSection.btn")}
        </a>
      </div>
    </div>
  );
};
