import axios from "axios";
import { useEffect, useState } from "react";
import { BiSolidRightArrow } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { PuffLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
export const Submitted = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/job/${id}`)
      .then((data) => {
        setData(data.data[0]);
        if (data.data[0].gameLanguage === 0) {
          i18n.changeLanguage("mn");
        } else if (data.data[0].gameLanguage === 1) {
          i18n.changeLanguage("rs");
        } else if (data.data[0].gameLanguage === 2) {
          i18n.changeLanguage("en");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [id]);

  if (data === null) {
    return (
      <div className="w-full centered min-h-screen ">
        <PuffLoader size={100} color="#F48D7E" />
      </div>
    );
  }
  return (
    <div>
      <div className="flex items-center justify-center my-10 container mx-auto w-full ">
        <ul className="flex items-center gap-1 sm:gap-5 text-[11px] sm:text-base ">
          <li className="font-semibold">{data?.createdBy}</li>
          <li>
            <BiSolidRightArrow />
          </li>
          <li className="underline font-semibold">{data?.job}</li>
          <li>
            <BiSolidRightArrow />
          </li>
          <li className="underline text-[#056D71]">
            {t("Submitted.breadCrumbs")}
          </li>
        </ul>
      </div>
      <div className="min-h-screen centerd container mx-auto mt-[50px] sm:mt-[150px]">
        <div className=" flex justify-center items-center px-10">
          {i18n.language === "mn" ? (
            <h1 className="text-sm sm:text-2xl text-center ">
              <span className="font-bold">{data?.createdBy}</span>{" "}
              {t("Submitted.createdBy")}
              <span className="font-bold">{data?.job} </span> <br />
              {t("Submitted.announcedBy")}
            </h1>
          ) : i18n.language === "rs" ? (
            <h1 className="text-sm sm:text-2xl text-center ">
              {t("Submitted.announcedBy")}{" "}
              <span className="font-bold">{data?.job}</span>
              <br />
              {t("Submitted.createdBy")}{" "}
              <span className="font-bold">{data?.createdBy}</span>
            </h1>
          ) : i18n.language === "en" ? (
            <h1 className="text-sm sm:text-2xl text-center ">
              {t("Submitted.announcedBy")}{" "}
              <span className="font-bold">{data?.job}</span>
              <br />
              {t("Submitted.createdBy")}{" "}
              <span className="font-bold">{data?.createdBy}</span>
            </h1>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className=" flex my-[60px] sm:my-[100px] justify-center items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="183"
              height="172"
              viewBox="0 0 123 112"
              fill="none"
            >
              <path
                d="M118.259 38.1976C118.259 46.9552 114.897 55.367 108.892 61.5895C95.0689 75.9173 81.6618 90.858 67.3226 104.667C64.0359 107.786 58.822 107.672 55.6767 104.412L14.3652 61.5895C1.87827 48.6456 1.87827 27.7494 14.3652 14.8058C26.9748 1.73501 47.5172 1.73501 60.1267 14.8058L61.6285 16.3623L63.1292 14.8067C69.175 8.53652 77.409 5 86.0104 5C94.6119 5 102.845 8.53618 108.892 14.8058C114.897 21.0287 118.259 29.4401 118.259 38.1976Z"
                stroke="#ABABAB"
                strokeWidth="8.49445"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="flex justify-center items-center text-[12px] sm:text-base mt-5">
          <p>
            {t("Submitted.bottomFrontMsg")}
            <span className=" ms-1 font-bold">
              {t("Submitted.invitation")}
            </span>{" "}
            {t("Submitted.bottomBackMsg")}
          </p>
        </div>
      </div>
    </div>
  );
};
